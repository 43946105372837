

































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { TrainerDocument } from '@/api/interfaces/trainerdocument'

export default defineComponent({
  props: {
    trainer: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      isSubmiting: false,
      trainerDocuments: [
      {
        fileName: '',
        file: null,
        trainerDocumentType: '',
      },
    ] as Array<TrainerDocument>,
    }
  },
  created () {
    (this as any).load()
  },
  computed: {
    ...mapState('TrainerDocumentType', {
      listTraineeDocumentType: 'list',
    }),
  },
  methods: {
    ...mapActions('user', {
      sendErrorMessage: 'sendErrorMessage',
    }),
    ...mapActions('TrainerDocument', { loadDocumentTrainer: 'load' }),
    ...mapActions('TrainerDocumentType', ['load']),
    ...mapActions('trainerForm', ['upload']),
    newDoc () {
      const trainerDocumentTypeNotSelected = this.trainerDocuments.filter(trainerDocument => trainerDocument.trainerDocumentType === '')

      if (trainerDocumentTypeNotSelected.length === 0) {
        const baseDocument: TrainerDocument = {
          fileName: '',
          file: null,
          trainerDocumentType: '',
        }
        this.trainerDocuments.push(baseDocument as never)
      }
    },
    submit () {
      this.isSubmiting = true
      this.trainerDocuments.forEach(async doc => {
        await this.sendDoc(doc as TrainerDocument)
      })
      this.isSubmiting = false
      this.$emit('close')
    },
    resetDocList () {
      this.trainerDocuments = [
        {
          fileName: '',
          file: null,
          trainerDocumentType: '',
        },
      ]
    },
    async sendDoc (doc: TrainerDocument) {
      if (doc.file && doc.fileName) {
        const formData: FormData = new FormData()
        formData.append('file', (doc as any).file as any)
        formData.append('fileName', (doc as any).fileName as string)
        formData.append('trainer', this.trainer.id as string)
        formData.append(
          'trainerDocumentType',
        doc.trainerDocumentType as string,
        )
        try {
          await (this as any).upload(formData)
          this.$emit('close')
          this.resetDocList()
        } catch (e) {
          this.sendErrorMessage(e.response.data.error)
        }
      }
    },
  },
})
